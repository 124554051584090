<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the consent question details</h4>
      <form autocomplete="off">
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="label" name="question-label">Question</label>
              <vs-textarea
                :success="!errors.first('basic.question') && questionnaire.question!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.question') ? true : false)"
                v-validate="'required'"
                name="question"
                data-vv-as="Question"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.question')"
                v-model="questionnaire.question"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="label" name="treatment-type">Treatment type</label>
              <v-select multiple :closeOnSelect="false"
                label="name"
                :options="treatments"
                v-model="selectedTreatment"
                name="treatmentId"
                class="w-full"
              />
            </div>
          </vs-col>
          <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-checkbox
                :success="!errors.first('basic.shouldShowToAll') && questionnaire.shouldShowToAll!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.shouldShowToAll') ? true : false)"
                name="shouldShowToAll"
                data-vv-as="shouldShowToAll"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.shouldShowToAll')"
                v-model="questionnaire.shouldShowToAll"
                type="checkbox"
              >
                Show To All
              </vs-checkbox>
            </div>
          </vs-col>-->
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-checkbox
                :success="!errors.first('basic.isEnabled') && questionnaire.isEnabled!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.isEnabled') ? true : false)"
                name="isEnabled"
                data-vv-as="isEnabled"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.isEnabled')"
                v-model="questionnaire.isEnabled"
                type="checkbox"
              >
                Enable
              </vs-checkbox>
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <label class="label" style="font-size: 1rem;">Is this question mandatory?</label>
              <ul class="leftx" style="font-size: 1rem;">
                <li>
                  <vs-radio
                    v-model="questionnaire.is_mandatory" vs-name="mandatory"
                    vs-value="yes">Yes
                  </vs-radio>
                </li>
                <li>
                  <vs-radio v-model="questionnaire.is_mandatory" vs-name="mandatory"
                    vs-value="no">No
                  </vs-radio>
                </li>
              </ul>
            </div>
          </vs-col>
           <vs-col >
            <div class="m-5">
              <vs-checkbox
                :success="!errors.first('basic.populateTreatmentPlans') && questionnaire.populateTreatmentPlans!=''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="(errors.first('basic.populateTreatmentPlans') ? true : false)"
                name="populateTreatmentPlans"
                data-vv-as="populateTreatmentPlans"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.populateTreatmentPlans')"
                v-model="questionnaire.populateTreatmentPlans"
                type="checkbox"
              >
                Populate Treatment Plans
              </vs-checkbox>
            </div>
          </vs-col>
          <vs-col>
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
                :disabled="isButtonDisabled"
              >
                Create Question
              </vs-button>
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import { mapActions } from "vuex";
import vSelect from 'vue-select'

export default {
  name: "questionnaireCreate",
  props: {
    backRoute: {
      type: String,
      default: "ConsentQuestionnaire"
    }
  },
  components:{
    'v-select': vSelect,
  },
  data: () => ({
    questionnaire: {
      question:'',
      shouldShowToAll: false,
      isEnabled: false,
      treatmentId: '',
      is_mandatory: "no"
    },
    treatments:[{
      _id: '',
      isAvailableToAll: '',
      name: '',
      description: ''
    }],
    selectedTreatment:[],
    isButtonDisabled: false,
  }),
  methods: {
    ...mapActions("questionnaire", ["createQuestionnaire", "fetchTreatmentPlans"]),
    getTreatmentPlans(){
      this.fetchTreatmentPlans().then(res => {
        this.treatments = res.data.data;
      })
    },
    async saveData() {
      let self = this;
      let isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate()

      if (isValid) {
        this.isButtonDisabled = true;

        let treatmentIds = [];
        this.selectedTreatment.forEach(function (item) {
          if(item._id){
            treatmentIds.push({'id': item._id});
          }
        })

        this.questionnaire.treatmentId = treatmentIds;
        this.questionnaire.is_mandatory = this.questionnaire.is_mandatory === "yes"
        let info = this.questionnaire
        await this.createQuestionnaire({info})
          .then(res => {
            self.$vs.notify({
              title: "Question Created",
              text: "Question created Successfully.",
              color: "success"
            });
            self.$router.push("/super-admin/questionnaire");
            this.isButtonDisabled = false;
          })
          .catch(err => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to created questionnaire. Try again!",
              color: "danger"
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            this.isButtonDisabled = false;
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function(value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, "")
        });
      });
    }
  },
  mounted(){
  },
  created() {
    this.getTreatmentPlans();
  }
};
</script>
